$white: #ffff;
$black: #000000;
$sand: #f5f5f5;

$primary: #f0cab7;
$secondary: #ea7d89;
$app-grey: #f0f2f5;
$dark-grey: #d9d9d9;

$lavender-blush: #fff0f5;
$app-theme-color: #c92c6d;
