@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap');

:root {
  --header-height: 3rem;
  --nav-width: 68px;
  --first-color-light: #afa5d9;
  --white-color: #f7f6fb;
  --body-font: 'Nunito', sans-serif;
  --normal-font-size: 1rem;
  --z-fixed: 999;
  --app-dark: #001c30;
  --app-blue: #176b87;
}

*,
::before,
::after {
  box-sizing: border-box;
}

.ant-table .ant-table-container .ant-table-content table thead.ant-table-thead .ant-table-cell {
  background-color: $primary;
}

// Editable Table Cell/Row Style //
.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid $dark-grey;
  background-color: $white;
  border-radius: 4px;
}

.focus-btn {
  opacity: 0.5;
}

.focus-btn:hover {
  opacity: 1;
}

// Editable Table Cell/Row Style //

.ant-card {
  box-shadow: inset 0 0 6px $primary;
}

.form-card {
  box-shadow: inset 0 0 6px $primary;
}

.sticky-footer {
  position: sticky;
  bottom: 32px;
  background-color: $lavender-blush;
  padding-top: 6px;
  padding-bottom: 6px;
}

.sticky-section {
  z-index: 100;
  position: sticky;
  bottom: 0;
  background-color: $lavender-blush;
  padding-top: 8px;
}

.sticky-top {
  position: sticky;
  top: 65px;
  background: $white;
  z-index: 20;
  box-shadow: 0px 15px 10px -15px #111;
}

body {
  position: relative;
  margin: var(--header-height) 0 0 0;
  padding-top: 8px;
  padding-right: 8px;
  padding-left: 8px;
  font-family: 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: var(--normal-font-size);
  transition: 0.5s;
  min-height: auto;
  background: $lavender-blush;
}

.ant-message-notice-content {
  margin-top: 90px;
  background-color: red;
}

.ant-table-cell {
  padding: 6px 6px !important;
}

th {
  background-color: $primary !important;
}

.primary-bg {
  background-image: linear-gradient($primary, $secondary);
}

.primary-color {
  color: $primary !important; //Primary Color
}

.secondary-bg {
  background-color: $secondary !important; //Secondary Background Color
}

.secondary-color {
  color: $secondary !important; //Secondary Color
}

.text-bold {
  font-weight: bold;
}

.fw-600 {
  font-weight: 600;
}

.font-16 {
  font-size: 16px;
}

a {
  text-decoration: none;
}

.header {
  width: 100%;
  height: var(--header-height);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  z-index: var(--z-fixed);
  transition: 0.5s;
  box-shadow: 0px 15px 10px -15px $black;
}

.header_toggle {
  color: var(--app-dark);
  font-size: 1.5rem;
  cursor: pointer;
}

.header_img {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
}

.header_img img {
  width: 40px;
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -30%;
  width: var(--nav-width);
  height: 100vh;
  background-color: var(--first-color);
  padding: 0.5rem 1rem 0 0;
  transition: 0.5s;
  z-index: var(--z-fixed);
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  padding-top: 1rem;
}

.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav_logo {
  margin-bottom: 2rem;
}

.nav_logo-icon {
  font-size: 1.25rem;
  color: var(--app-dark);
}

.nav_logo-name {
  color: var(--app-dark);
  font-weight: 700;
}

.nav_link {
  position: relative;
  color: $black;
  margin-bottom: 1rem;
  transition: 0.3s;
}

.nav_link:hover {
  color: $white;
  background-color: $secondary;
}

.nav_icon {
  font-size: 1.25rem;
}

.show {
  left: 0;
}

.body-pd {
  padding-left: calc(var(--nav-width) + 1rem);
}

.active {
  color: $black;
  font-weight: 700;
  text-decoration: underline;
  text-underline-offset: 10px;
  text-decoration-color: $secondary;
  font-size: 20px;
}

.active::before {
  content: '';
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--white-color);
}

.height-100 {
  height: 100%;
}

.overflow-auto {
  overflow: auto !important;
}

//  Text Font Sizes  //
.font-20 {
  font-size: 20px !important;
}

.font-14 {
  font-size: 14px !important;
}

//  Text Font Sizes  //

.cursor-pointer {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  body {
    margin: calc(var(--header-height) + 1rem) 0 0 0;
  }

  .header {
    height: calc(var(--header-height) + 1rem);
    padding: 0 2rem 0 calc(var(--nav-width) + 0rem);
  }

  .header_img {
    width: 40px;
    height: 40px;
  }

  .header_img img {
    width: 45px;
  }

  .l-navbar {
    left: 0;
    padding: 0rem 0.5rem 0 0;
  }

  .show {
    width: calc(var(--nav-width) + 156px);
  }

  .body-pd {
    padding-left: calc(var(--nav-width) + 156px);
  }

  .customer-body-pd {
    padding-left: calc(var(--nav-width) + 0rem);
  }
}

.mf-program {
  > div {
    background-color: $sand;
    border-radius: 10px;

    &:first-child {
      height: calc(40vh - 64px);
    }

    &:nth-child(2) {
      height: calc(25vh - 64px);
    }

    &:last-child {
      height: calc(40vh - 64px);
    }
  }
}

.mf-program-map {
  > div {
    background-color: $sand;
    border-radius: 10px;

    &:first-child {
      height: calc(50vh - 64px);
    }

    &:last-child {
      height: calc(50vh - 64px);
    }
  }
}

.logoImg {
  width: 24px;
  height: 24px;
}

.info-card {
  margin: 1rem;
}

.card-item {
  display: flex;
}

.profile-cards {
  display: grid;
}

.form-img {
  height: 500px;
}

.table-pagination {
  position: absolute;
  right: 10px;
  padding: 1rem;
}

.form-card {
  border-radius: 5px;
  background-color: $white;
  padding: 16px;
}

.card-block-step3 {
  width: 33.33%;
}

.card-block {
  width: 50%;
}

.product-card-area {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-flow: wrap;
  gap: 50px;
}

.form-content {
  margin: 16px;
}

.drop-down-right {
  float: right;
}

.product-card {
  width: 450px;
}

.span-space {
  padding-left: 16px;
  padding-right: 16px;
}

@media screen and (max-width: 992px) {
  .card-area {
    display: contents;
  }

  .card-block {
    width: 100%;
  }
}

.loading-indicator {
  position: fixed;
  z-index: 999;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
}

.width-30 {
  width: 30%;
}

.width-40 {
  width: 40%;
}

.width-100 {
  width: 100%;
}

.spans {
  display: flex;
  width: 100%;
}

.tier-quantity-span {
  padding-left: 10px;
  padding-right: 10px;
  width: 45%;
  text-align: right;
}

.card-area {
  display: flex;
  justify-content: center;
  width: 100%;
}

.bottom-space {
  padding-bottom: 16px;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-form-item {
  margin-bottom: 12px;
}

:where(.css-dev-only-do-not-override-12upa3x).ant-form-vertical .ant-form-item-label {
  padding: 0;
}

.ant-message-error {
  background-color: #ffb7b6;
  border-radius: 4px;
  padding: 10px;
}

.ant-message-success {
  background-color: #b7f7c4;
  border-radius: 4px;
  padding: 10px;
}

.ant-message-info {
  background-color: #b3e6f5;
  border-radius: 4px;
  padding: 10px;
}

.ant-message-warning {
  background-color: #fbe7c6;
  border-radius: 4px;
  padding: 10px;
}

.ant-message-notice-content {
  padding: 0 !important;
  color: black;
}

.span-tier {
  width: 25%;
}

.tier-spans {
  width: 33%;
}

.tier-span {
  width: 100%;
}

.checkbox-button {
  justify-content: right;
}

.week-dropdown {
  width: 380px;
}

.payment-form-child {
  width: 100%;
}

.customer-edit-form-child {
  width: 100%;
}

.payment-form {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  width: 100%;
}

.editable-input {
  text-align: right;
}

.table-title-row {
  justify-content: space-between;
  align-items: center;
}

.print-card {
  border-radius: 5px;
  background-color: white;
  padding: 16px;
  box-shadow: 0 0 6px #f0cab7;
  height: 100%;
  width: 100%;
}

.no-padding-card .ant-card-body {
  padding: 0;
}

.button-align {
  align-self: center;
  margin-bottom: -4px;
}

.word-break {
  word-break: break-word;
}

// ------------- Amplify CSS ------------- //

.amplify-button[data-variation='primary'] {
  background: $app-theme-color;
}

.amplify-tabs-item[data-state='active'] {
  color: $app-theme-color;
  border-color: $app-theme-color;
}

[data-amplify-authenticator] [data-amplify-router] {
  border-color: $app-theme-color;
}

.amplify-button--link {
  color: $app-theme-color;
}

.amplify-flex .amplify-button__loader-wrapper {
  color: $white;
}

.dropdown-container .ant-form-item .ant-row .ant-col label {
  font-weight: 600;
}

.ql-toolbar.ql-snow {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.ql-container.ql-snow {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.title-grey {
  color: #353935;
}

.panel-header .ant-collapse-header {
  padding: 5px 8px !important;
}

.panel-header .ant-collapse-header .ant-collapse-header-text {
  color: $app-theme-color;
  font-weight: 600;
}

.ant-collapse-content.ant-collapse-content-active .ant-collapse-content-box {
  padding: 0 !important;
}

.ant-tabs-tab {
  background: rgba(0, 0, 0, 0.08) !important;
  border: 1px solid #b4b4b3 !important;
  font-weight: 600 !important;
}
.ant-tabs-tab-active {
  background-color: $white !important;
  border-top: 1px solid !important;
  border-left: 1px solid !important;
  border-right: 1px solid !important;
  border-bottom: 5px solid !important;
}
.ant-tabs-nav {
  margin-bottom: 0 !important;
}

.reports-tab-container {
  background: rgba(0, 0, 0, 0.08);
  padding-top: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.report-tabs .ant-tabs-nav-list,
.ant-tabs-tab {
  flex-grow: 1;
  justify-content: center;
}

.ant-card-head-title {
  white-space: normal !important;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.shipping-content {
    color: #050505;
    background: #fdd7db;
}